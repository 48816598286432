export const it = {
  message: {
    hello: 'ciao mondo',
    not_found_search: 'Oops! Nessun risultato. Aggiungi elemento.'
  },
  label: {
    name_language: 'italiano',
    title: 'Titolo',
    denominazione: 'Denominazione',
    subtitle: 'Sottotitolo',
    description: 'Descrizione',
    excerpt: 'Introduzione',
    metadata: 'SEO',
    tag: 'Tag',
    available_char: 'caratteri disponibili',
    social_title: 'Titolo Account Social',
    social_description: 'Descrizione Account Social',
    google_title: 'Titolo Google',
    google_description: 'Descrizione Google',
    tosee: "Cosa Vedere",
    todo: "Cosa Fare",
    SULLATAVOLA: "Cosa Mangiare",
    DANONPERDERE: "Da Non Perdere",
    NEIDINTORNI: "Nei Dintorni",
    DISTANZACAPOL: "Distanza dal capoluogo",
    description: "Dove si Trova",
    NOTE: "Note",
    PERCHE: "Perchè",
    QUANDO: "Quando",
    CAMERACON: "Camera Con",
    DIVERTIRSI: "Divertimento",
    INFORMA: "Come Tenersi in Forma",
    APPUNTAMENTI: "Appuntamenti",
    INFORMAZIONI: "Informazioni",
    ARRIVAREAUTO: "Come raggiungere in Auto",
    COMMENTIAUTOBUS: "Commenti Autobus",

    button_text: "Testo Pulsante",
    button_link: "Link Pulsante",

    maps_address: 'Cerca Indirizzo',
    maps_street: 'Via',
    maps_city: 'Città',
    maps_zip_code: 'CAP',
    maps_regione: 'Regione',
    maps_lat: 'Latitudine',
    maps_long: 'Longitudine',
    author: 'Autore',
    sunday: 'Domenica',
    monday: 'Lunedi',
    tuesday: 'Martedi',
    wednesday: 'Mercoledi',
    thursday: 'Giovedi',
    friday: 'Venerdi',
    saturday: 'Sabato',
    exception_date_title: 'Gestisci Eccezioni nel Periodo Selezionato',
    contacts: {
      telephone: "telefono",
      website: "website",
      email: "email",
      fax: "fax",

    },
  },
  entity_name: {
    users: 'utenti',
    events: 'eventi',
    places: 'luoghi',
    news: 'news',
    iat: 'iat',
    destination: 'destination',
    itinerari: 'itinerari',
    itinerary: 'itinerari',
    interessi: 'interessi',
    tag: 'tag',
    menu: 'menu',
    media: 'media',
    download: 'download',
    opendata: 'opendata',
    webcam: 'webcam'
  },

  button: {},
  placeholder: {
    title: 'Titolo',
    denominazione: 'Denominazione',
    subtitle: 'Sottotitolo',
    description: 'Descrizione',
    excerpt: 'Introduzione',
    metadata: 'Metadati',
    available_char: 'caratteri disponibili',
    social_title: 'Titolo Account Social',
    social_description: 'Descrizione Account Social',
    google_title: 'Titolo Google',
    google_description: 'Descrizione Google',
    tosee: "Cosa Vedere",
    todo: "Cosa Fare",
    SULLATAVOLA: "Cosa Mangiare",
    DANONPERDERE: "Da Non Perdere",
    NEIDINTORNI: "Nei Dintorni",
    DISTANZACAPOL: "Distanza dal capoluogo",
    description: "Dove si Trova",
    NOTE: "Note",
    PERCHE: "Perchè",
    QUANDO: "Quando",
    CAMERACON: "Camera Con",
    DIVERTIRSI: "Divertimento",
    INFORMA: "Come Tenersi in Forma",
    APPUNTAMENTI: "Appuntamenti",
    INFORMAZIONI: "Informazioni",
    ARRIVAREAUTO: "Come raggiungere in Auto",
    COMMENTIAUTOBUS: "Commenti Autobus",

    maps_address: 'Digita Indirizzo',
    maps_street: 'Via',
    maps_city: 'Città',
    maps_zip_code: 'CAP',
    maps_regione: 'Regione',
    maps_lat: 'Latitudine',
    maps_long: 'Longitudine',
  },
  dropdown_item: {},
  card_title: {},
  alert_messages: {},
  languages_string: {
    it: 'italiano',
    en: 'inglese',
    ja: 'cinese',
    ru: 'russo',
    de: 'tedesco',
    fr: 'francese',
    es: 'spagnolo'
  }
};



