<template>
    <nav v-if="this.$route.name!='media'" class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <span class="navbar-brand">{{routeName}}</span>
            <button
                    class="navbar-toggler navbar-burger"
                    type="button"
                    @click="toggleSidebar"
                    :aria-expanded="$showSidebar"
                    aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav ml-auto">
                    <drop-down class="nav-item" title="Account" title-classes="nav-link" icon="ti-user">
                        <a class="dropdown-item" :href="process.env.VUE_APP_API_BASEPATH + '/#/user/profile'">Profilo Utente</a>
                        <a class="dropdown-item" :href="process.env.VUE_APP_API_BASEPATH + '/#'" @click="logout()">Esci</a>
                    </drop-down>
                </ul>
            </div>&nbsp;&nbsp;&nbsp;
        </div>
    </nav>
</template>

<script>
    import axios from "axios";

    export default {
        computed: {
            routeName() {
                const {name} = this.$route;
                if (this.$route.name == "Voci Categoria") {

                    var nomeTabella = 'Voci Categoria';

                    switch (this.$route.params.id) {
                        case 1:
                            // code block
                            nomeTabella = "elenco categorie";
                            break;
                        case 5:
                            // interessi
                            nomeTabella = "Interessi";
                            break;

                        case 6:
                            // tarhgt
                            nomeTabella = "Target";
                            break;

                        case 4:
                            // durata itinerari
                            nomeTabella = "durata Itinerari";
                            break;
                        default:
                            // code block
                            break;
                    }

                    return nomeTabella;

                }

                if (this.$route.path == "/users/new") return "gestione utente";

                if (this.$route.path == "/users/new/user%2Froles")
                    return "gestione ruolo";

                return this.capitalizeFirstLetter(name);
            }
        },

        data() {
            return {
                activeNotifications: false
            };
        },

        methods: {
            change_language(language) {
                this.$i18n.locale = language;
                this.$setLanguage(language);
            },

            change_list(url, id, route, parent) {
                this.$router.push({name: 'Voci Categoria', params: {id: id, path: route, parent: parent}})
            },

            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },

            toggleNotificationDropDown() {
                this.activeNotifications = !this.activeNotifications;
            },

            closeDropDown() {
                this.activeNotifications = false;
            },

            toggleSidebar() {
                this.$showSidebar = (!this.$showSidebar);
            },

            hideSidebar() {
                this.$showSidebar = (false);
            },

            changeStatus: function (esito) {
                this.$root.unlogged = true;
                var user = [];
                this.$user.set(user);
                location.reload();
            },

            logout: function () {
                var self = this;

                if (token == null) {
                    self.changeStatus(false);
                    return;
                }

                axios({
                    method: "get",
                    url: this.$url + "logout",
                    headers: {
                        Authorization: "Bearer " + token
                    },
                    data: {}
                })
                    .then(function (response) {
                        if (response.data.code == "200") {
                            self.$removeToken();
                            self.changeStatus(false);
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        self.changeStatus(false);
                    });
            }
        },
    };
</script>
