<template>
 <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul>
          <li v-html="$branding.title">
           
          </li>
        </ul>
      </nav>
      <div class="copyright d-flex flex-wrap">
        &copy; Powered by <a href="https://www.altrama.com" target="_blank">&nbsp; Altrama Italia S.R.L.</a>
      </div>
    </div>
  </footer> 
</template>

<script>
export default {};
</script>

<style>
</style>
