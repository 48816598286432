<template>
  <div>
    <div class="wrapper">
      <div
        :class="['sidebar', 'mb-20', collapsedMenu ? 'collapsed' : 'expanded']"
        data-background-color="black"
        data-active-color="success"
      >
        <div class="sidebar-wrapper" id="style-3">
          <sidebar-menu
            :menu="menu"
            :collapsed="this.collapsedMenu"
            :relative="false"
            :showChild="false"
            :showOneChild="true"
            @toggle-collapse="onToggleCollapse"
            width="200px"
          >
            <div slot="header" class="py-2 text-center">
              <a
                href="#/dashboard"
                class="logo-holder"
                :alt="$branding.title"
              >
                <img
                  style="width: 100%; max-width:100%;"
                  class="logo-img"
                  :src="$branding.logo"
                  :alt="$branding.title"
                />
              </a>
              <p v-if="!this.collapsedMenu" v-html="$branding.titleHtml">

              </p>
              <span class="small italic">{{ username }}</span>
              <hr
                style="
                  border-color: rgba(255, 255, 255, 0.22);
                  margin: 10px 10px 0px 10px;
                "
              />
            </div>
            <span slot="toggle-icon">
              <span class="fa fa-arrows-h"></span>
            </span>
            <span slot="dropdown-icon">
              <span class="fa fa-caret-down"></span>
            </span>
          </sidebar-menu>
        </div>
      </div>

      <div :class="['main-panel', collapsedMenu ? 'collapsed' : 'expanded']">
        <dashboard-content @click.native="toggleSidebar"></dashboard-content>
        <content-footer></content-footer>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import { menuBuilder } from "../../app";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  data() {
    return {
      username: "",
      menu: [],
      collapsedMenu: false,
    };
  },

  created: async function () {
    let user = await this.$api.user.current();
    this.username = user.first_name + " " + user.last_name;


    let menu = await menuBuilder(this.$api);

    this.menu = await this.validateMenu(menu)

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 960) {
        this.collapsedMenu = true;
      } else if (window.innerWidth > 960) {
        this.collapsedMenu = false;
      }
    });
    if (window.innerWidth <= 960) {
      this.collapsedMenu = true;
    } else if (window.innerWidth > 960) {
      this.collapsedMenu = false;
    }
  },

  methods: {
    async validateMenu(menuItem) {
        for( let i = 0; i < menuItem.length; i++ ){
          const canSee = menuItem[i].abilities === true || (await  this.$api.user.can(menuItem[i].abilities) );
          if(!canSee){
            delete menuItem[i];
          }else{
            if(  menuItem[i].child &&  menuItem[i].child.length > 0 ){
              menuItem[i].child = await this.validateMenu(  menuItem[i].child );
            }
          }
        }
        return menuItem.filter( (i) => i !== undefined && i !== null );
    },

    onToggleCollapse() {
      this.collapsedMenu = !this.collapsedMenu;
    },

    toggleSidebar() {
      if (this.$showSidebar) {
        this.$showSidebar = (false);
      }
    }
  },
};
</script>

<style>
.dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}

.wrapper .sidebar .logo p {
  line-height: 52px;
  margin: 0px;
}

.wrapper .sidebar .vsm_collapsed .logo {
  padding: 13px 6px;
  margin: 0;
}

.vsm_collapsed .logo .logo-img img {
  width: 40px;
  height: auto;
}

.vsm_collapsed .logo p {
  display: none;
}

.wrapper .sidebar.collapsed {
  width: 53px;
}

.main-panel.collapsed {
  width: calc(100% - 53px);
}

.nav-open .main-panel.collapsed,
.nav-open .main-panel.expanded {
  width: calc(100% - 53px);
}

@media screen and (max-width: 992px) {
  .main-panel.expanded {
    width: calc(100% - 200px);
  }
}

td {
  vertical-align: middle;
}
</style>

