export const en = {
    
    
  message: {
    hello: 'hello world',
    not_found_search:'Oops! No elements found. Add element'
  },
  label:{
    name_language: 'english',
    title:'Title',
    subtitle:'Subtitle',
    description:'Description',
    excerpt:'Summary',
    metadata:'Metadata',
    tag:'Tag',
    available_char:'available chars',
    social_title:'Account Social Title',
    social_description:'Account Social description',
    google_title:'Google Title',
    google_description:'Google Description',
    author:'Author',
    maps_address:'Type Address',
    maps_street:'Street',
    maps_city:'City',
    maps_zip_code:'Zip Code',
    maps_regione:'District',
    maps_lat:'Latitude',
    maps_long:'Longitude',
    exception_date_title:'Exception date'
  },
  button:{},
  placeholder:{
    title:'Title',
    subtitle:'Subtitle',
    description:'Description',
    excerpt:'Summary',
    metadata:'Metadata',
    available_char:'available chars',
    social_title:'Facebook Title',
    social_description:'Facebook description',
    google_title:'Google Title',
    google_description:'Google Description',
    maps_address:'Address',
    maps_street:'Street',
    maps_city:'City',
    maps_zip_code:'Zip Code',
    maps_regione:'District',
    maps_lat:'Latitude',
    maps_long:'Longitude',
  },
  dropdown_item:{},
  card_title:{},
  alert_messages:{},
  languages_string:{
        it:'italian',
        en:'english',
        ja:'chinese'
    }
  
}


